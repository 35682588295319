import classNames from "classnames";

import { useWeb3Context } from "@/contexts/web3";
import { usePoolCoeffs, usePoolData } from "@/hooks/position";
import { useApply } from "@/hooks/util";

import { checkIsWatch } from "../../util/position-helpers";
import WatchPosition from "./watch-position";

export default function WatchPositions({
  chainName,
  poolAddress,
}: {
  poolAddress?: `0x${string}`;
  chainName: string;
}) {
  const poolCoeffs = usePoolCoeffs(poolAddress);
  const poolData = usePoolData(poolAddress);
  const positionParams = useApply(poolData.getPositionParams, poolCoeffs);
  const { getPoolByAddress } = useWeb3Context();

  const isWatch = checkIsWatch(poolData.position?.type);
  const isFarm = getPoolByAddress(poolAddress)?.pool.isFarm;
  const isMarginCall =
    isWatch && positionParams?.margin && positionParams.margin.lt(0.1);

  // useEffect(() => {
  //   if (poolAddress) {
  //     dispatch?.({
  //       type: "positionType",
  //       payload: { address: poolAddress, type },
  //     });
  //     setActiveWatches((previousState) => ({
  //       ...previousState,
  //       [poolAddress]: isWatch,
  //     }));

  //     return () => {
  //       dispatch?.({
  //         type: "clean",
  //         payload: { address: poolAddress },
  //       });
  //     };
  //   }
  // }, [type, poolAddress, dispatch, isWatch, setActiveWatches]);

  return (
    isWatch &&
    !isFarm && (
      <div className={classNames("longshort", { "margin-call": isMarginCall })}>
        <WatchPosition
          address={poolAddress}
          position={poolData.position!}
          params={positionParams}
          base={poolData.base}
          quote={poolData.quote}
          chainName={chainName}
        />
      </div>
    )
  );
}
