import styled from "styled-components";

export const Wrapper = styled.div<{ $isActive?: boolean }>`
  width: 48px;
  height: 48px;
  min-width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ $isActive }) =>
    $isActive ? "var(--fill-accent-secondary)" : "var(--fill-secondary)"};
  svg path {
    fill: var(--icon-primary);
  }
`;
