import ButtonUnstyled from "@marginly/ui/components/button";
import NextLink from "next/link";
import styled from "styled-components";

export const Link = styled(NextLink)`
  display: flex;
  justify-content: center;

  @media (min-width: 1024px) {
    justify-content: flex-start;
  }
`;

export const Button = styled(ButtonUnstyled)`
  && {
    background-color: var(--fill-accent-primary);
    gap: 0;
    padding-right: 6px;
    p {
      color: var(--text-invert-primary);
    }
    &:hover {
      background-color: var(--fill-accent-primary);
    }
    svg path {
      fill: var(--text-invert-primary);
    }
  }
`;
