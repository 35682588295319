import styled from "styled-components";

export const TradeBlock = styled.div`
  background: var(--fill-negative-bg);
  border-radius: 8px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  isolation: isolate;
  height: 48px;
  min-width: 48px;
  max-width: 48px;
  margin-right: 16px;

  .icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
    object-position: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &.invert {
      filter: invert(var(--svg-invert));
    }
  }

  &.up {
    background: var(--fill-positive-bg);
    .trade__percents {
      color: var(--text-positive);
    }
  }

  .graph {
    width: calc(100% + 24px);
  }
`;

export const Badge = styled.div`
  background: #99ffee;
  border-radius: 32px;
  box-shadow: 0 8px 24px 0 rgba(51, 20, 0, 0.08),
    0 4px 8px 0 rgba(51, 20, 0, 0.04);
`;
