import styled from "styled-components";

export const Wrapper = styled.div`
  background: var(--fill-elevated);
  box-shadow: 0px 8px 24px 0px rgba(51, 20, 0, 0.08),
    0px 4px 8px 0px rgba(51, 20, 0, 0.04);
  border-radius: 32px;
  padding: 20px 24px 24px;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  .token {
    width: 48px;
    height: 48px;
  }
`;

export const RightSide = styled.div`
  margin-left: auto;
  text-align: right;
`;
