export const EmptyImage = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.12 11.984C3.084 11.984 0.612 9.512 0.612 6.476C0.612 3.44 3.084 0.968 6.12 0.968C9.156 0.968 11.628 3.44 11.628 6.476C11.628 9.512 9.156 11.984 6.12 11.984ZM9.624 9.248C10.236 8.492 10.596 7.52 10.596 6.476C10.596 4.004 8.592 2 6.12 2C5.076 2 4.104 2.36 3.348 2.972L9.624 9.248ZM6.12 10.952C7.164 10.952 8.136 10.592 8.892 9.98L2.616 3.704C2.004 4.46 1.644 5.432 1.644 6.476C1.644 8.948 3.648 10.952 6.12 10.952Z"
      fill="currentColor"
    />
  </svg>
);
