import BigNumber from "bignumber.js";

import { PositionType } from "../transform/position";

const FP96 = {
  Q96: BigInt(2) ** BigInt(96),
};

const TWO__48 = BigInt(2) ** BigInt(48);

export function convertNumberToFP96(num: number): { inner: bigint } {
  return { inner: BigInt(num) * FP96.Q96 };
}
export function convertFP96ToNumber(fp96Value: bigint): number {
  const tmp = fp96Value / TWO__48;
  return Number(tmp) / 2 ** 48;
}

export function toHumanString(fp96Value: bigint): string {
  return BigNumber(fp96Value.toString())
    .div(BigNumber(FP96.Q96.toString()))
    .toString();
}

export function positionEnumToStr(posType: PositionType): string {
  switch (posType) {
    case PositionType.Uninitialized:
      return "Uninitialized";
    case PositionType.Lend:
      return "Lend";
    case PositionType.Short:
      return "Short";
    case PositionType.Long:
      return "Long";
    default:
      return "error";
  }
}
