import T from "@marginly/ui/components/typography";
import React from "react";

import * as S from "./styled";

interface Props {
  image: string;
  title: string;
  description: string;
  href: string;
  onClick?: () => void;
}

export function MailLink({ href, onClick, title, description, image }: Props) {
  return (
    <S.Wrapper href={href} onClick={onClick}>
      <img src={image} alt="" />
      <S.RightSide>
        <T>{title}</T>
        <S.Description caption secondary>
          {description}
        </S.Description>
      </S.RightSide>
      <S.ArrowRightIcon />
    </S.Wrapper>
  );
}
