"use client";

import { useAccount } from "wagmi";

import { useMarginlyApi } from "@/hooks/api";
import { Partner } from "@/util/server/env";

const BLAST_CONTEST_ID = 6;
const CAMELOT_CONTEST_ID = 7;

export const useContestRank = (
  partner: Partner | undefined,
  chainName: string,
) => {
  const { address } = useAccount();

  const getContestId = () => {
    if (partner === Partner.Camelot) {
      return CAMELOT_CONTEST_ID;
    }
    if (chainName === "blastSepolia") {
      return BLAST_CONTEST_ID;
    }
    return undefined;
  };

  const contestId = getContestId();

  const placement = useMarginlyApi({
    enabled: Boolean(address && contestId),
    endpoint: "leaderboard.placement",
    args: [address, contestId],
  });

  const { data: userInfo } = useMarginlyApi({
    enabled: Boolean(address && contestId),
    endpoint: "leaderboard.memberInfo",
    args: [contestId, address],
  });

  return { position: placement.data?.position, userInfo };
};
