"use client";

import T from "@marginly/ui/components/typography";
import NextLink from "next/link";
import { useMemo } from "react";
import type { Chain } from "viem";
import { useAccount } from "wagmi";

import Space from "@/components/common/space";
import { CHAINKEY_MAP } from "@/constants/chains";
import { useWeb3Context } from "@/contexts/web3";
import { useMarginlyApi } from "@/hooks/api";
import { markWritable } from "@/util/core";
import { trackEarnStart, trackSwapStart } from "@/util/tracking";

import { useClientMounted } from "../../util/client-only";
import { FarmPositions } from "../farm-positions";
import LendPositions from "../lend-positions";
import WatchPositions from "../watch-positions";
import { MailLink } from "./main-link";
import * as S from "./styled";

interface Props {
  chain: Chain;
  registered?: boolean;
}

type LinkName = "farm" | "earn" | "trade" | "swap";
interface LinkInfo {
  image: string;
  title: string;
  description: string;
  href: string;
  onClick?: () => void;
}

export function HomepageBottom(props: Props) {
  const chainName = CHAINKEY_MAP[props.chain.id] as string;

  const { pools, internalChainId, getPoolByAddress } = useWeb3Context();
  const account = useAccount();

  const hasMounted = useClientMounted();
  const dArgs =
    internalChainId && account.address
      ? markWritable([
          internalChainId,
          account.address,
          pools.map(({ address }) => address),
        ] as const)
      : undefined;

  const { data: positionDetails } = useMarginlyApi({
    enabled: Boolean(dArgs),
    endpoint: "positionDetailsMulti",
    args: dArgs!,
  });

  const isAccountDisconnected = account.status === "disconnected";

  const haveTradePosition = positionDetails
    ?.filter(
      (position) => !getPoolByAddress(position.marginlyPool)?.pool.isFarm,
    )
    ?.some(({ positionType }) => ["Long", "Short"].includes(positionType));

  const haveEarnPosition = positionDetails?.some(
    ({ positionType }) => positionType === "Lend",
  );

  const haveFarmPosition = positionDetails?.some(
    (position) =>
      position.positionType === "Long" &&
      getPoolByAddress(position.marginlyPool)?.pool.isFarm,
  );

  const havePosition =
    haveTradePosition || haveEarnPosition || haveFarmPosition;

  const { data: userPoints } = useMarginlyApi({
    enabled: Boolean(account.address && (haveFarmPosition || haveEarnPosition)),
    endpoint: "userPoints",
    args: [account.address],
  });

  const linkList = useMemo(() => {
    const links: LinkName[] = [];
    if (!haveTradePosition) links.push("trade");
    if (!haveEarnPosition) links.push("earn");
    if (!haveFarmPosition) links.push("farm");
    links.push("swap");
    return links;
  }, [haveEarnPosition, haveFarmPosition, haveTradePosition]);

  const showLinkList = linkList.length === 2 || linkList.length === 3;

  const earnLinkInfo: LinkInfo = {
    description: "By supplying liquidity.",
    title: "Earn",
    image: "/images/home-earn.png",
    href: `/${chainName}/earn`,
    onClick: () => trackEarnStart("homepage"),
  };

  const farmLinkInfo: LinkInfo = {
    description: "High APYs with leveraged strategies.",
    title: "Farm",
    image: "/images/home-farm.png",
    href: `/${chainName}/farm`,
  };

  const swapLinkInfo: LinkInfo = {
    description: "Exchange any asset.",
    title: "Swap",
    image: "/images/home-swap.png",
    href: hasMounted
      ? `/${chainName}/swap?in=${
          props.chain.testnet ? pools[0]?.baseToken?.toLowerCase() : ""
        }&out=${pools[0]?.quoteToken?.toLowerCase()}`
      : `/${chainName}/swap`,
    onClick: () => trackSwapStart("homepage"),
  };

  const tradeLinkInfo: LinkInfo = {
    description: "With up to 20× leverage.",
    title: "Trade",
    image: "/images/home-trade.png",
    href: `${chainName}/all-trades`,
  };

  const linkInfoByName: Record<LinkName, LinkInfo> = {
    earn: earnLinkInfo,
    farm: farmLinkInfo,
    swap: swapLinkInfo,
    trade: tradeLinkInfo,
  };

  if (!positionDetails && !isAccountDisconnected) return null;

  if (havePosition) {
    return (
      <S.PositionWrapper>
        {haveTradePosition && (
          <S.PositionsSection>
            <T headerM>Trading</T>
            <S.PositionsCol>
              {pools?.map(({ address }) => (
                <WatchPositions
                  key={address}
                  poolAddress={address}
                  chainName={chainName}
                />
              ))}
            </S.PositionsCol>
          </S.PositionsSection>
        )}
        {haveEarnPosition && (
          <S.PositionsSection>
            <T headerM>Lending</T>
            <LendPositions
              chainName={chainName}
              pools={pools}
              userPoints={userPoints}
            />
          </S.PositionsSection>
        )}
        {haveFarmPosition && (
          <S.PositionsSection>
            <T headerM>Farming</T>
            <FarmPositions
              pools={pools}
              chainName={chainName}
              userPoints={userPoints}
            />
          </S.PositionsSection>
        )}
        {showLinkList && (
          <div>
            <S.MailListTitle headerM>Leverage your crypto</S.MailListTitle>
            <S.MainLinksRow>
              {linkList.map((link) => (
                <MailLink {...linkInfoByName[link]} key={link} />
              ))}
            </S.MainLinksRow>
          </div>
        )}
      </S.PositionWrapper>
    );
  }

  return (
    <S.Wrapper>
      <NextLink href={earnLinkInfo.href} onClick={earnLinkInfo.onClick}>
        <S.HomepageUnit className="earn">
          <div>
            <T headerM>{earnLinkInfo.title}</T>
            <Space heightMobile={4} height={4} />
            <T body secondary>
              {earnLinkInfo.description}
            </T>
          </div>
          <img src={earnLinkInfo.image} className="pic" alt="" />
          <S.SafeShield>
            <img src="/images/security.svg" alt="" />
            <T>Safe</T>
          </S.SafeShield>
        </S.HomepageUnit>
      </NextLink>
      <NextLink href={farmLinkInfo.href}>
        <S.HomepageUnit className="farm">
          <div>
            <T headerM>{farmLinkInfo.title}</T>
            <Space heightMobile={4} height={4} />
            <T body secondary>
              {farmLinkInfo.description}
            </T>
          </div>
          <img src={farmLinkInfo.image} className="pic" alt="" />
        </S.HomepageUnit>
      </NextLink>

      <S.Subgrid className="double">
        <NextLink href={tradeLinkInfo.href}>
          <S.HomepageUnit className="trade">
            <div>
              <T headerM>{tradeLinkInfo.title}</T>
              <Space heightMobile={4} height={4} />
              <T body secondary>
                {tradeLinkInfo.description}
              </T>
            </div>
            <img src={tradeLinkInfo.image} className="pic pic--trade" alt="" />
          </S.HomepageUnit>
        </NextLink>

        {!props.chain.testnet || props.chain.name === "blastSepolia" ? (
          <NextLink href={swapLinkInfo.href} onClick={swapLinkInfo.onClick}>
            <S.HomepageUnit className="swap">
              <div>
                <T headerM>{swapLinkInfo.title}</T>
                <Space heightMobile={4} height={4} />
                <T body secondary>
                  {swapLinkInfo.description}
                </T>
              </div>
              <img src={swapLinkInfo.image} className="pic pic--swap" alt="" />
            </S.HomepageUnit>
          </NextLink>
        ) : (
          <></>
        )}
      </S.Subgrid>
    </S.Wrapper>
  );
}
