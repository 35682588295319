import Button from "@marginly/ui/components/button";
import T from "@marginly/ui/components/typography";
import React from "react";

import Space from "@/components/common/space";

import imgDesktop from "./camelot-image-desktop.png";
import imgMobile from "./camelot-image-mobile.png";
import * as S from "./styled";
const LEADERBOARD_LINK = "https://marginly.com/contest/camelot";

interface Props {
  contestRank?: number;
  refCode?: string | null | undefined;
}

const contestEnds = true;

export function TopCamelotBanner({ contestRank, refCode }: Props) {
  const renderBannerTitle = () => {
    if (contestEnds) {
      return "Trading contest is over";
    }
    if (!refCode) {
      return "Not registered";
    }
    if (contestRank === -1) {
      return "You are registered";
    }
    return `Your Rank #${contestRank}`;
  };

  const renderBannerSubtitle = () => {
    if (contestEnds) {
      return "See your results in Camelot Competition.";
    }
    if (!refCode) {
      return "Register to compete in the Camelot trading contest.";
    }
    return "Compete in the Camelot trading contest until March 27th.";
  };

  const renderButtonLabel = () => {
    if (contestEnds) {
      return "Check Rank";
    }
    if (!refCode) {
      return "Register";
    }
    return "Learn more";
  };

  return (
    <S.LeaderboardBanner className="camelot-banner">
      <T headerL>{renderBannerTitle()}</T>
      <Space height={12} heightMobile={4} />
      <T body secondary>
        {renderBannerSubtitle()}
      </T>
      <Space height={26} heightMobile={26} />
      <S.ActionRow>
        <a href={LEADERBOARD_LINK} target="_blank">
          <Button sm className="button_orange">
            <T action>{renderButtonLabel()}</T>
          </Button>
        </a>
      </S.ActionRow>
      <S.CamelotImgDesktop src={imgDesktop.src} />
      <S.CamelotImgMobile src={imgMobile.src} />
    </S.LeaderboardBanner>
  );
}
