import React from "react";

import { TokenIcon } from "../token-icon";
import * as S from "./styled";

interface Props {
  token: string | undefined;
  isActive?: boolean;
}

export function ActiveTokenImage({ token, isActive }: Props) {
  return (
    <S.Wrapper $isActive={isActive}>
      {token && <TokenIcon token={token} />}
    </S.Wrapper>
  );
}
