"use client";
import { useMarginlyApi } from "@/hooks/api";

export const useRank = (contestNumber: number, address?: string) => {
  const { data: scores } = useMarginlyApi({
    enabled: address != null,
    endpoint: "leaderboard.score",
    args: [contestNumber, address],
  });

  return scores?.find((score) => score.type === "Pnl");
};
