"use client";
import { useReducer } from "react";

import { getEntries } from "@/util/core";

export enum PositionTypePrior {
  MarginCall = 1,
  Watch,
  Lend,
}

export interface HomeState {
  positionTypes?: Record<`0x${string}`, PositionTypePrior | undefined>;
  priorType?: PositionTypePrior;
}

type Action =
  | {
      type: "positionType";
      payload: { address: `0x${string}`; type: PositionTypePrior | undefined };
    }
  | {
      type: "clean";
      payload: { address: `0x${string}` };
    };

const reducer = (state: HomeState, action: Action) => {
  switch (action.type) {
    case "clean": {
      const { address } = action.payload;
      let priorType: undefined | PositionTypePrior;

      const positionsTypes = getEntries(state.positionTypes).reduce<
        HomeState["positionTypes"]
      >((prev, [k, v]) => {
        if (k === address || !v) {
          return prev;
        }

        if (!priorType) {
          priorType = v;
        }

        if (priorType > v) {
          priorType = v;
        }

        return { ...prev, [k]: v };
      }, {});

      return { ...state, priorType, positionsTypes };
    }
    case "positionType":
      const { address, type } = action.payload;

      const nextTypes: Record<`0x${string}`, PositionTypePrior | undefined> = {
        ...state.positionTypes,
        [address]: type,
      };

      const priorType = getEntries(nextTypes).reduce<
        undefined | PositionTypePrior
      >((prev, [_, type]) => {
        if (!type) {
          return prev;
        }

        if (!prev) {
          return type;
        }

        if (type < prev) {
          return type;
        }

        return prev;
      }, undefined);

      return { ...state, positionTypes: nextTypes, priorType };
  }

  return state;
};

export const useHomepage = () => useReducer(reducer, {});
export type HomeDispatch = ReturnType<typeof useHomepage>[1];
