import cn from "classnames";
import NextLink from "next/link";

import { Card } from "@/components/homepage/styled";
import { UserPointsResponse } from "@/types/api";
import { PoolConfigWithTokens } from "@/types/core";
import { trackLendInfoStart } from "@/util/tracking";

import { Rows } from "./lend.styled";
import LendBlock from "./lend-block";

export default function LendPositions({
  chainName,
  pools,
  userPoints,
}: {
  pools?: PoolConfigWithTokens[];
  chainName: string;
  userPoints: UserPointsResponse | undefined;
}) {
  return (
    <div className={cn("lend")}>
      <NextLink
        href={`/${chainName}/lend-info`}
        onClick={() => trackLendInfoStart("homepage")}
      >
        <Card>
          <Rows>
            {pools?.map(({ address }) => (
              <LendBlock
                userPoints={userPoints}
                key={address}
                address={address}
              />
            ))}
          </Rows>
        </Card>
      </NextLink>
    </div>
  );
}
