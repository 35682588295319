import { AVLTree } from "@/util/avl/tree";

export function getRangeFromTree<K, V>(
  tree: AVLTree<K, V> | undefined,
  from: K,
  to: K,
) {
  const result: [K, V][] = [];

  if (!tree?.range(from, to, (value, time) => result.push([time, value]))) {
    console.error("tree not initialized");
    return [];
  }

  return result;
}
