import T from "@marginly/ui/components/typography";
import React, { useState } from "react";

import { getTokenSymbol } from "@/app/util";
import { TokenImage } from "@/components/common/token";
import { fullFarmDateOptions } from "@/components/farm/constants";
import { FarmPositionModal } from "@/components/farm/modals/position-modal";
import { useWeb3Context } from "@/contexts/web3";
import { usePoolCoeffs, usePoolData } from "@/hooks/position";
import { useApply } from "@/hooks/util";
import { UserPointsResponse } from "@/types/api";
import { daysDisplay } from "@/util/days-display";
import { formatCoin } from "@/util/format-coin";
import { formatPercent } from "@/util/formatters";

import { daysTillMaturity } from "../../../util/get-day-difference";
import { checkIsWatch } from "../../../util/position-helpers";
import { Noop } from "../../util";
import * as S from "./styled";
import { useNetPosition } from "./use-net-position";
interface Props {
  poolAddress: `0x${string}`;
  chainName: string;
  userPoints: UserPointsResponse | undefined;
}

export function FarmPositionCard({
  poolAddress,
  chainName,
  userPoints,
}: Props) {
  const pointDetails = poolAddress && userPoints?.detailsByPool[poolAddress];
  const { getPoolByAddress } = useWeb3Context();

  const poolCoeffs = usePoolCoeffs(poolAddress);
  const poolData = usePoolData(poolAddress);
  const positionParams = useApply(poolData.getPositionParams, poolCoeffs);
  const isWatch = checkIsWatch(poolData.position?.type);
  const maturityDate = getPoolByAddress(poolAddress)?.pool.maturityDate;
  const quoteSymbol = poolData.quote?.symbol;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const leverage = positionParams?.leverage;
  const { days, isHarvest } = daysTillMaturity(maturityDate);

  const { featureAmount, farmed, farmingBaseApy, actualAmount } =
    useNetPosition({
      leverage,
      maturityDate: maturityDate || "",
      poolAddress,
    });

  if (!isWatch) {
    return null;
  }

  const renderSubtitle = () => {
    if (maturityDate !== undefined) {
      return `Before ${new Date(maturityDate).toLocaleDateString(
        "en-US",
        fullFarmDateOptions,
      )}`;
    }
    if (farmed > 0) {
      return (
        <>
          + ${formatCoin(farmed)} ${getTokenSymbol(quoteSymbol)}
        </>
      );
    }
    return null;
  };

  const renderRightSide = () => {
    if (maturityDate === undefined) {
      return (
        <>
          <T>{formatPercent((farmingBaseApy || 0) / 100)}</T>
          <T caption secondary>
            APY
          </T>
        </>
      );
    }
    if (isHarvest) {
      return (
        <>
          <T>Harvest</T>
          <T caption secondary>
            Now
          </T>
        </>
      );
    }
    return (
      <>
        <T>{daysDisplay(days || 0)}</T>
        <T caption secondary>
          Left
        </T>
      </>
    );
  };

  return (
    <>
      <S.Wrapper onClick={() => setIsModalVisible(true)}>
        <TokenImage token={quoteSymbol} className="token" />
        <div>
          <T>
            {maturityDate === undefined
              ? formatCoin(actualAmount)
              : formatCoin(featureAmount)}{" "}
            {getTokenSymbol(quoteSymbol)}
          </T>
          <T caption secondary>
            {renderSubtitle()}
          </T>
        </div>
        <S.RightSide>{renderRightSide()}</S.RightSide>
      </S.Wrapper>
      {isModalVisible ? (
        <FarmPositionModal
          poolAddress={poolAddress}
          maturityDate={maturityDate}
          chainName={chainName}
          onClose={() => {
            document.body.style.overflow = "auto";
            setIsModalVisible(false);
          }}
          points={pointDetails?.farmingPoints}
          pointsPerDay={pointDetails?.farmingPointsPerDay}
          leverage={leverage}
        />
      ) : (
        <Noop />
      )}
    </>
  );
}
