"use client";
import NextLink from "next/link";
import { useContext, useEffect, useMemo, useState } from "react";
import { t } from "ttag";
import { useAccount } from "wagmi";

import { ChainSegmentParams } from "@/app/types";
import Space from "@/components/common/space";
import { MemberInfo } from "@/external/types/member";
import { RamsesLink } from "@/partnership/components/ramses-link";
import { TraderJoeLink } from "@/partnership/components/trader-joe-link";
import { Partner } from "@/util/server/env";

// import { GetMarginlyNft } from "@/external/components/get-marginly-nft";
// import { usePaperPlaneNft } from "@/hooks/nft";
import { Noop } from "../util";
import { HomePageContext } from ".";
import { TopCamelotBanner } from "./banners/top-camelot-banner";
import CamelPic from "./images/camel.png";
import CamelotPic from "./images/camelot.svg";
import JoePic from "./images/joe.svg";
import MarginCallPic from "./images/mcall.png";
import MoonPic from "./images/moon.png";
import NoonPic from "./images/noon.png";
import SunPic from "./images/sun.png";
import { PositionTypePrior } from "./state";
import {
  ConnectButton,
  Description,
  Title,
  TopWrapper,
  UnderTitle,
} from "./styled";

type TimeOfDay = "morning" | "afternoon" | "evening";

interface Greeting {
  pic: string;
  heading: string;
  subtext: string;
}

const greetingByPartner: Record<Partner, Greeting> = {
  [Partner.Camelot]: {
    pic: CamelotPic.src,
    heading: t`Camelot × Marginly`,
    subtext: t`Go 20× long or short on your favorite DEX.`,
  },
  [Partner.TraderJoe]: {
    pic: JoePic.src,
    heading: t`Trader Joe contest`,
    subtext: t`Compete until February XX.`,
  },
  [Partner.Ramses]: {
    pic: CamelPic.src,
    heading: t`Ramses contest`,
    subtext: t`The contest is over. Thanks for your participation.`,
  },
};

const greetingByTime: Record<TimeOfDay, Greeting> = {
  morning: {
    pic: SunPic.src,
    heading: t`Good morning`,
    subtext: t`How will you start it?`,
  },
  afternoon: {
    pic: NoonPic.src,
    heading: t`Good afternoon`,
    subtext: t`So, what are you gonna do?`,
  },
  evening: {
    pic: MoonPic.src,
    heading: t`Good evening`,
    subtext: t`So, what are you gonna do?`,
  },
};

const getTimeOfDay = (time: number): TimeOfDay => {
  const date = new Date(time);
  const hours = date.getHours();

  if (hours > 18 || hours < 4) {
    return "evening";
  }
  if (hours > 11) {
    return "afternoon";
  }
  return "morning";
};

const linkByPartner: Record<Partner, React.ReactNode> = {
  [Partner.Camelot]: <Noop />,
  [Partner.Ramses]: <RamsesLink />,
  [Partner.TraderJoe]: <TraderJoeLink />,
};

interface Props extends ChainSegmentParams {
  partner: Partner | undefined;
  contestRank?: number;
  userInfo?: MemberInfo;
}

export default function HomepageTop({
  chain,
  partner,
  contestRank,
  userInfo,
}: Props) {
  const { state } = useContext(HomePageContext);
  const [timeOfDay, setTimeOfDay] = useState<TimeOfDay>();
  const hasMounted = typeof timeOfDay !== "undefined";
  const account = useAccount();

  useEffect(() => {
    setTimeOfDay(getTimeOfDay(Date.now()));
  }, []);

  const dayTimeGreeting = useMemo(
    () => timeOfDay && greetingByTime[timeOfDay],
    [timeOfDay],
  );
  const partnerGreeting = partner && greetingByPartner[partner];

  // const { hasNft } = usePaperPlaneNft();
  const isWalletConnected = account.isConnected;

  const renderTitle = () => {
    if (!hasMounted) {
      return <Noop />;
    }
    if (partnerGreeting) {
      return (
        <>
          <img src={partnerGreeting.pic} alt="🌞" />
          {partnerGreeting.heading}
        </>
      );
    }
    if (state?.priorType === PositionTypePrior.MarginCall) {
      return (
        <>
          <img src={MarginCallPic.src} alt="🌞" />
          {t`Margin call`}
        </>
      );
    }
    if (state?.priorType === PositionTypePrior.Watch) {
      return (
        <>
          <img src={dayTimeGreeting?.pic} alt="🌞" />
          {t`Watch your position`}
        </>
      );
    }
    return (
      <>
        <img src={dayTimeGreeting?.pic} alt="🌞" />
        {dayTimeGreeting?.heading}
      </>
    );
  };

  const renderSubtitle = () => {
    if (!hasMounted) {
      return `\xA0`;
    }
    if (chain === "polygonMumbai" || chain === "arbitrumGoerli") {
      return t`Top up your balance on our Discord.`;
    }
    if (partnerGreeting) {
      return partnerGreeting.subtext;
    }
    // if (!isWalletConnected) return t`Connect your wallet first.`;
    if (state?.priorType === PositionTypePrior.MarginCall)
      return t`Close the position or add a deposit, otherwise we will liquidate it.`;
    if (state?.priorType === PositionTypePrior.Watch)
      return t`It's beautiful, isn't it?`;
    if (state?.priorType === PositionTypePrior.Lend)
      return t`Lend more or withdraw? That's the question.`;

    return dayTimeGreeting?.subtext ?? <>&nbsp;</>;
  };

  const renderAction = () => {
    if (!hasMounted) {
      return <Noop />;
    }
    if (chain === "polygonMumbai" || chain === "arbitrumGoerli") {
      return (
        <NextLink
          href={
            chain === "polygonMumbai"
              ? "https://discord.com/channels/1097798692003655781/1163755614841880626"
              : chain === "arbitrumGoerli"
              ? "https://discord.com/channels/1097798692003655781/1124273397321453669"
              : ""
          }
          target="_blank"
          rel="noreferrer noopener"
        >
          <ConnectButton fit sm className="secondary">
            Get Test Tokens
          </ConnectButton>
        </NextLink>
      );
    }
    if (partner) {
      return linkByPartner[partner];
    }
    return <Noop />;
  };

  const renderTopSection = () => {
    if (partner === Partner.Camelot && isWalletConnected) {
      return (
        <TopCamelotBanner
          contestRank={contestRank}
          refCode={userInfo?.refCode}
        />
      );
    }
    return (
      <div>
        <Title>{renderTitle()}</Title>
        <Space height={6} heightMobile={6} />
        <Description>
          <UnderTitle>{renderSubtitle()}</UnderTitle>
          {renderAction()}
        </Description>
      </div>
    );
  };

  return <TopWrapper>{renderTopSection()}</TopWrapper>;
}
