import {
  type Context,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { FRAME_TO_SECONDS } from "@/constants/chart";
import { useWeb3Context } from "@/contexts/web3";
import { MarginlyApiContext } from "@/hooks/api";
import type { ICandle } from "@/hooks/candles";
import { getSeconds } from "@/util/core";

import { getRangeFromTree } from "../util/get-range-from-tree";

type UnwrapContext<T> = T extends Context<infer U> ? U : never;
type Unsub = ReturnType<
  NonNullable<UnwrapContext<typeof MarginlyApiContext>["watch"]>
>;

/** @deprecated move to hook arguments */
const DISPLAY_TIMEFRAME = "FifteenMinute" as const;
const DAY = 3600 * 24;

const DISPLAY_TIME_PENDLE = "OneHour" as const;
const WEEK = 3600 * 24 * 7;

interface Props {
  internalChainId: number;
  poolAddress: `0x${string}`;
  onUpdate?: () => void;
  isPendle?: boolean;
}

export const usePricePoints = ({
  internalChainId,
  poolAddress,
  isPendle,
  onUpdate,
}: Props) => {
  const frame = isPendle ? DISPLAY_TIME_PENDLE : DISPLAY_TIMEFRAME;
  const offset = isPendle ? WEEK : DAY;
  const key = useMemo(
    () => ({
      chainId: internalChainId,
      poolAddress,
      frame: frame,
    }),
    [frame, internalChainId, poolAddress],
  );

  const { getValues, getTree, watch } = useContext(MarginlyApiContext);

  const [now] = useState(getSeconds());
  const from = now - offset + FRAME_TO_SECONDS[frame];

  const [points, setPoints] = useState<[number, ICandle][]>(() => {
    return getRangeFromTree(getTree?.(key), from, now);
  });

  const [lastPoint, setLastPoint] = useState<[number, ICandle] | undefined>();

  const unsub = useRef<Unsub>();
  const [hasMounted, setHasMounted] = useState(false);

  if (!unsub.current && hasMounted) {
    const unsub15Min = watch?.(key, (result) => {
      const now = result.period[1];
      const from = now - offset + FRAME_TO_SECONDS[frame];
      const tree = getTree?.(key);
      const points = getRangeFromTree(tree, from, now);
      setPoints(points);
    });

    const unsubMin = watch?.({ ...key, frame: "OneMinute" }, (result) => {
      const [point] = result.points.slice(-1);
      setLastPoint(point);
      onUpdate?.();
    });

    unsub.current = () =>
      Promise.all([unsub15Min?.(), unsubMin?.()].filter(Boolean)).then((r) => {
        return r.reduce((a, b) => a || b, undefined)!;
      });

    getValues?.(key, from, now)
      .then((points) => {
        setPoints(points);
      })
      .catch(console.error);
  }

  useEffect(() => {
    setHasMounted(true);

    return () => {
      // console.log("unsub, pre");
      unsub.current?.();
    };
  }, []);

  return lastPoint ? [...points, lastPoint] : points;
};
