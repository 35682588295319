import T from "@marginly/ui/components/typography";
import React from "react";

import ArrowRightIcon from "../../../icons/arrow-right-icon";
import * as S from "./styled";
interface Props {
  href: string;
  text: string;
}

export function PartnerLink({ href, text }: Props) {
  return (
    <S.Link href={href} target="_blank">
      <S.Button sm secondary fit>
        <T action>{text}</T>
        <ArrowRightIcon />
      </S.Button>
    </S.Link>
  );
}
