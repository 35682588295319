import { useParams } from "next/navigation";
import React from "react";
import { useAccount } from "wagmi";

import { CONTEST_NUMBER } from "@/partnership/constants";
import { useRank } from "@/partnership/hooks/use-rank";

import { PartnerLink } from "../partner-link";

const numberFormatter = new Intl.NumberFormat("en-US").format;

export function RamsesLink() {
  const { chain } = useParams();
  const { address } = useAccount();

  const rank = useRank(CONTEST_NUMBER, address);

  const buttonText = rank
    ? `Your Rank #${numberFormatter(rank.place)}`
    : "Check Results";

  return <PartnerLink href={`${chain}/trading-contest`} text={buttonText} />;
}
