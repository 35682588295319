import styled from "styled-components";

export const LeaderboardBanner = styled.div`
  border-radius: var(--rounding-radius-xxl, 32px);
  background: linear-gradient(97deg, #75835d 0%, #c3d4a5 100.67%);
  position: relative;
  padding: 24px;
  isolation: isolate;
  &.camelot-banner {
    background: var(--background-accent-secondary);
    &::before,
    &::after {
      display: none;
    }
  }
  &::before,
  &::after {
    content: " ";
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg width='88' height='8' viewBox='0 0 88 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='4' height='8' fill='%239BA885'/%3E%3Crect x='12' width='4' height='8' fill='%239BA885'/%3E%3Crect x='24' width='64' height='8' fill='%239BA885'/%3E%3C/svg%3E%0A");
    background-size: contain;
    right: 32px;
    bottom: 32px;
    width: 64px;
    height: 8px;
    z-index: -1;
  }
  &::before {
    bottom: auto;
    top: 32px;
    width: 72px;
    background-image: url("data:image/svg+xml,%3Csvg width='72' height='8' viewBox='0 0 72 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='4' height='8' fill='%239BA885'/%3E%3Crect x='12' width='4' height='8' fill='%239BA885'/%3E%3Crect x='24' width='4' height='8' fill='%239BA885'/%3E%3Crect x='36' width='24' height='8' fill='%239BA885'/%3E%3Crect x='68' width='4' height='8' fill='%239BA885'/%3E%3C/svg%3E%0A");
  }

  .headerL {
    color: var(--text-primary-on-dark, #fff);
  }
  .headerL,
  .action {
    font-weight: 400;
    font-variation-settings: "wght" 700;
  }
  p.secondary {
    color: rgba(242, 237, 235, 0.48);
  }
  .button_yellow {
    background: #fcfc03;
    &:hover {
      background: #f1f100;
    }
    .action {
      color: var(--text-primary-on-brand, #000);
    }
  }

  .button_orange {
    background: #ffaf1d;
    &:hover {
      background: #ffaf1d;
    }
    .action {
      color: var(--text-primary-on-brand, #000);
    }
  }
  .nft_img {
    width: 20px;
  }
  .button_tokens {
    background: #ffffff;
    &:hover {
      background: #e6e1e1;
    }
    p {
      color: #000000;
    }
  }
`;

export const ActionRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
  }
`;
