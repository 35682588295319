import T from "@marginly/ui/components/typography";
import styled from "styled-components";

import { Card } from "@/components/homepage/styled";

export const PositionWrapper = styled.div`
  display: grid;
  grid-template-columns: 100%;
  gap: 26px;
  @media (min-width: 1024px) {
    gap: 16px;
  }
`;

export const MailListTitle = styled(T)`
  margin-left: 8px;
  margin-bottom: 14px;
  @media (min-width: 1024px) {
    margin-left: 0;
    display: none;
  }
`;

export const MainLinksRow = styled.div`
  display: flex;
  gap: 24px;
  overflow: auto;
  margin: 0 -24px;
  padding: 0 24px 8px 24px;
  -ms-overflow-style: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &::-webkit-scrollbar {
    width: 0px;
    opacity: 0;
    background-color: transparent;
    display: none;
  }
  @media (min-width: 1024px) {
    overflow: visible;
    padding: 0;
    margin: 0;
    & > * {
      min-width: 0;
      flex: 1;
    }
  }
`;

export const PositionsSection = styled.div`
  border-radius: 40px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  .headerM {
    margin-left: 8px;
  }
  @media (min-width: 1024px) {
    padding: 32px;
    background-color: var(--fill-accent-secondary);
    gap: 24px;
    & > * {
      flex: 1;
    }
    .headerM {
      margin-left: 0;
      line-height: 40px;
      letter-spacing: 0.32px;
      font-size: 32px;
    }
    ${Card} {
      background: var(--fill-elevated);
    }
  }
  @media (min-width: 1440px) {
    flex-direction: row;
  }
`;

export const PositionsCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 40px;

  .mobile-banner {
    display: block;
  }

  .desktop-banner {
    display: none;
  }

  @media (min-width: 1024px) {
    gap: 24px;
    margin-bottom: 0;
  }

  /* @media (min-width: 1440px) {
    display: grid;
    grid-template-columns: 2.1fr 1fr;
    grid-template-areas:
      "earn double"
      "farm double";

    & > *:nth-child(2) {
      grid-area: earn;
    }
    & > *:nth-child(3) {
      grid-area: farm;
    }

    & > *:nth-child(4) {
      grid-area: double;
    }

    .mobile-banner {
      display: none;
    }

    .desktop-banner {
      display: block;
    }
  } */
`;

export const HomepageUnit = styled.div`
  border-radius: var(--rounding-radius-xl, 24px);
  background: var(--fill-elevated, #fff);
  box-shadow: 0px 4px 8px 0px rgba(51, 20, 0, 0.04),
    0px 8px 24px 0px rgba(51, 20, 0, 0.08);
  padding: var(--spacing-space-20, 20px) var(--spacing-space-24, 24px)
    var(--spacing-space-24, 24px) var(--spacing-space-24, 24px);
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  min-height: 108px;
  isolation: isolate;
  &.earn,
  &.farm {
    padding-right: 112px;
  }

  .pic {
    position: absolute;
    bottom: 6px;
    right: 16px;
    width: 96px;
    object-fit: contain;
    &--trade {
      right: -8px;
      bottom: -8px;
      width: 80px;
    }
    &--swap {
      right: -8px;
      bottom: -8px;
      width: 96px;
    }
  }

  &.trade,
  &.swap {
    min-height: 180px;
    align-items: flex-start;
  }

  @media (min-width: 768px) {
    align-items: flex-start;
    &.trade,
    &.swap {
      min-height: 180px;
      align-items: flex-start;
    }
  }

  @media (min-width: 1024px) {
    min-height: 200px;
    height: 100%;
    border-radius: 32px;
    padding: 32px;
    &.trade {
      min-height: 328px;
    }
    &.swap {
      min-height: 136px;
    }
    .pic {
      width: 144px;
      bottom: 50%;
      right: 56px;
      transform: translateY(50%);
      &--trade,
      &--swap {
        width: 120px;
        bottom: 36px;
        left: 32px;
        transform: translateY(0);
      }
      &--swap {
        width: 144px;
        z-index: -1;
      }
    }
    &.earn,
    &.farm {
      padding-right: 208px;
    }
    .headerM {
      font-size: 32px;
      line-height: 40px;
      letter-spacing: 0.32px;
    }
  }

  // @media (min-width: 1440px) {
  //   .pic--swap {
  //     left: auto;
  //     right: 14px;
  //     bottom: 50%;
  //     transform: translateY(50%);
  //     width: 104px;
  //   }
  // }
`;

export const SafeShield = styled.div`
  border-radius: var(--rounding-radius-m, 16px);
  background: var(--fill-positive-bg, rgba(71, 89, 62, 0.72));
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  padding: var(--spacing-space-4, 4px) var(--spacing-space-12, 12px)
    var(--spacing-space-4, 4px) var(--spacing-space-4, 4px);
  display: flex;
  align-items: center;
  gap: 4px;
  top: 20px;
  right: 20px;
  position: absolute;

  p {
    color: var(--text-positive, #45be33);
    font-variation-settings: "wght" 600;
  }

  @media (min-width: 1024px) {
    top: auto;
    right: auto;
    left: 32px;
    bottom: 32px;
  }
`;

export const Subgrid = styled.div`
  display: flex;
  gap: 16px;
  & > * {
    flex: 1 1 50%;
  }

  @media (min-width: 768px) {
    gap: 24px;
  }
  /* @media (min-width: 1440px) {
    flex-direction: column;
    display: grid;
    // grid-template-rows: 2.64fr 1fr;
    grid-template-rows: 320px 136px;
  } */
`;
