import Typography from "@marginly/ui/components/typography";
import * as Class from "@marginly/ui/constants/classnames";
import cn from "classnames";
import NextLink from "next/link";
import { useEffect, useMemo, useState } from "react";

import { getTokenSymbol } from "@/app/util";
import Space from "@/components/common/space";
import { CHART_BACK_GREEN, CHART_BACK_RED } from "@/constants/common";
import { useWeb3Context } from "@/contexts/web3";
import { positionEnumToStr } from "@/contracts/common";
import {
  type PositionParams,
  PositionType,
} from "@/contracts/transform/position";
import { PoolPosition, usePoolData, usePositionsInfo } from "@/hooks/position";
import type { TokenDetails } from "@/hooks/token";
import { useApply } from "@/hooks/util";
import { getPriceAggregates, toChartCandleSeries } from "@/util/common";
import { formatNum } from "@/util/format";
import { formatCoin } from "@/util/format-coin";
import { trackWatchPositionStart } from "@/util/tracking";

import { usePricePoints } from "../../hooks/use-price-points";
import { getWatchLink } from "../../util/position-helpers";
import { SimplePriceSVGGraph } from "../common/simple-graph";
import { TokenIcon } from "../common/token";
import { EmptyImage } from "./images/empty-image";
import {
  Card,
  CardEarnFlex,
  LiquidationBlock,
  TradeColumn,
  TradePercents,
  TradeWatchCoinRow,
} from "./styled";
import { Badge, TradeBlock } from "./watch-position.styled";

const ANIMATION_TIMEOUT_MS = 2000;

const WatchPosition = ({
  address,
  base,
  params,
  position,
  quote,
  chainName,
}: {
  address?: `0x${string}`;
  base?: TokenDetails;
  position: PoolPosition;
  params?: PositionParams;
  quote?: TokenDetails;
  chainName: string;
}) => {
  const { internalChainId, getPoolByAddress } = useWeb3Context();
  const info = usePositionsInfo(address);

  const { uniswapAddress, isPendle } =
    useApply(getPoolByAddress, address)?.pool || {};

  const { refetch } = usePoolData(address);

  const points = usePricePoints({
    internalChainId: internalChainId ?? 0,
    poolAddress: uniswapAddress!,
    onUpdate: refetch,
    isPendle,
  });

  const candles = useMemo(() => points?.map(toChartCandleSeries), [points]);

  const price = getPriceAggregates(candles);

  const typeName = useMemo(
    () => positionEnumToStr(position.type ?? PositionType.Uninitialized),
    [position],
  );

  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAnimated(true);
    }, ANIMATION_TIMEOUT_MS);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const marginCallWarning = params?.margin && params.margin.lt(0.1);

  return (
    <NextLink
      href={getWatchLink({ address, chainName })}
      onClick={() => trackWatchPositionStart("homepage")}
    >
      <Card>
        <CardEarnFlex>
          <p className="card__title">
            {typeName} {formatCoin(params?.realBaseAmount)}
            &nbsp;
            {getTokenSymbol(base?.symbol)}
          </p>
          {marginCallWarning && (
            <Badge className={"card__waring-badge"}>
              <img src="/images/alert.svg" alt="" />
            </Badge>
          )}
        </CardEarnFlex>
        <p
          className={
            (info?.pnl ?? 0) >= 0
              ? "up card__position-percents"
              : "card__position-percents"
          }
        >
          {info?.pnl ? (
            <>
              {info.pnl >= 0 ? "+" : ""}
              {formatCoin(info.pnl)} {getTokenSymbol(info.pnlToken)}
            </>
          ) : null}
          {info?.pnlPercentage && Math.abs(info.pnlPercentage) > 0.01
            ? formatNum(Math.abs(info?.pnlPercentage), {
                postfix: "%",
                prefix: " / ",
                significantDecimals: 2,
              })
            : ""}
        </p>
        <Space height={20} heightMobile={20} />
        <TradeWatchCoinRow>
          <TradeBlock className={price?.up ? "up" : ""}>
            <SimplePriceSVGGraph
              candles={candles}
              width={64}
              height={64}
              color={price?.up ? CHART_BACK_GREEN : CHART_BACK_RED}
              className="graph"
            />
            {base?.symbol && <TokenIcon token={base.symbol} className="icon" />}
          </TradeBlock>
          <TradeColumn>
            <div>
              {formatCoin(params?.basePrice)} {getTokenSymbol(quote?.symbol)}
            </div>
            <LiquidationBlock className={marginCallWarning ? "margincall" : ""}>
              <EmptyImage />{" "}
              <Typography as="span" className={Class.Caption}>
                {" "}
                <span className={cn("hint", { animated })}>
                  <span>Liquidation at&nbsp;</span>
                </span>
                {formatCoin(info?.liquidationPrice)}{" "}
                {getTokenSymbol(quote?.symbol)}
              </Typography>
            </LiquidationBlock>
          </TradeColumn>
          {!marginCallWarning && (
            <TradePercents
              className={cn("trade__percents", { bad: !price?.up, animated })}
            >
              <Typography as="div" className={Class.Body}>
                {formatNum(price?.percent, {
                  prefix: price && price.diff?.gt(0) ? "+" : "",
                  postfix: "%",
                  dp: 2,
                })}
              </Typography>
              <Typography as="div" className={Class.Caption}>
                Today&nbsp;
              </Typography>
            </TradePercents>
          )}
        </TradeWatchCoinRow>
      </Card>
    </NextLink>
  );
};

export default WatchPosition;
