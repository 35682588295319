import styled from "styled-components";
export * from "../styled";

export const CamelotImgDesktop = styled.img`
  position: absolute;
  right: 24px;
  bottom: 24px;
  display: none;
  @media (min-width: 1024px) {
    display: block;
  }
`;

export const CamelotImgMobile = styled.img`
  position: absolute;
  right: 24px;
  bottom: 24px;
  width: 162px;
  object-fit: contain;
  @media (min-width: 1024px) {
    display: none;
  }
`;
