"use client";

import Button from "@marginly/ui/components/button";
import * as Class from "@marginly/ui/constants/classnames";
import styled, { keyframes } from "styled-components";

import { earnBarStyles } from "@/components/common/card";

const STRIPE_WIDTH = "57px";
const PENDING_BACKGROUND_ANIMATION = keyframes`
  0% {
		transform: translateX(-${STRIPE_WIDTH});
	}
	100% {
		transform: translateX(0);
	}
`;
const ANIMATION_DURATION_MS = "300ms";

export const Description = styled.div`
  display: grid;
  gap: 16px;
  @media (min-width: 1024px) {
    gap: 32px;
  }
`;

export const TokenName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 32px;
  line-height: 48px;
  font-variation-settings: "wght" 700;
  letter-spacing: 0.32px;
  gap: 8px;
  margin: 0;
  font-weight: normal;

  img {
    height: 32px;
    width: 32px;
    object-fit: contain;
    object-position: center;
    display: block;
  }
  @media (min-width: 1024px) {
    font-size: 40px;
    img {
      height: 48px;
      width: 40px;
    }
  }
`;

export const UnderTitle = styled.p`
  color: var(--text-secondary);
  margin: 0;
`;

export const ValuesSection = styled.div`
  padding: 3px 0;
  overflow-x: auto;
`;

export const TopWrapper = styled.div`
  margin-bottom: 16px;
  & > div:first-child {
    padding: 0 8px;
  }
  @media (min-width: 1024px) {
    margin-bottom: 34px;
    padding: 0 16px;
    & > div:first-child {
      padding: 0;
    }
  }
`;

export const CardEarnFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 8px;
`;

export const BottomRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

export const TradeCoinRow = styled.div`
  display: flex;
  gap: 16px;
  overflow: hidden;
  align-items: center;
  color: var(--text-primary);

  .trade {
    &__cointotals {
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        text-align: right;
      }
    }
    &__code {
      color: var(--text-secondary);
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-size: 12px;
      line-height: 16px;
      padding-left: 16px;
      position: relative;
      &::before {
        content: " ";
        position: absolute;
        left: 0;
        top: 50%;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.12 11.984C9.156 11.984 11.628 9.512 11.628 6.476C11.628 3.44 9.156 0.968 6.12 0.968C3.084 0.968 0.612 3.44 0.612 6.476C0.612 9.512 3.084 11.984 6.12 11.984ZM9.624 9.248L3.348 2.972C4.104 2.36 5.076 2 6.12 2C8.592 2 10.596 4.004 10.596 6.476C10.596 7.52 10.236 8.492 9.624 9.248ZM6.12 10.952C3.648 10.952 1.644 8.948 1.644 6.476C1.644 5.432 2.004 4.46 2.616 3.704L8.892 9.98C8.136 10.592 7.164 10.952 6.12 10.952Z' fill='%2372706E'/%3E%3C/svg%3E%0A");
        transform: translateY(-50%);
        width: 13px;
        height: 13px;
        background-position: center;
        background-size: contain;
      }
    }
  }

  .pnl {
    color: var(--text-positive);
    font-feature-settings: "clig" off, "liga" off;
    text-overflow: ellipsis;
  }
  .apr {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .score {
    margin-left: auto;
  }
`;

export const Coins = styled.div`
  display: flex;
  & > * {
    &:nth-child(1) {
      margin-right: -16px;
    }
  }
`;

export const LendIconWrapper = styled.div<{ $isActive?: boolean }>`
  width: 48px;
  height: 48px;
  min-width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ $isActive }) =>
    $isActive ? "var(--fill-accent-secondary)" : "var(--fill-secondary)"};
  svg path {
    fill: var(--icon-primary);
  }
  &:nth-child(1) {
    margin-right: -16px;
  }
`;

export const TradeWatchCoinRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--text-primary);
`;

export const TradeColumn = styled.div`
  flex-grow: 1;
`;

export const Card = styled.div`
  background: var(--fill-elevated);
  box-shadow: 0px 8px 24px 0px rgba(51, 20, 0, 0.08),
    0px 4px 8px 0px rgba(51, 20, 0, 0.04);
  border-radius: 32px;
  padding: 20px 24px 24px;
  color: var(--text-primary);

  &.card__trade {
    position: relative;
    isolation: isolate;
    &::before {
      content: "All";
      color: var(--text-secondary, rgba(66, 61, 60, 0.64));
      letter-spacing: 0.48px;
      position: absolute;
      top: 26px;
      right: 24px;
      z-index: -1;
      transition: 300ms ease-out;
      font-weight: 400;
      font-variation-settings: "wght" 700;
    }
    &::after {
      content: "";
    }
    &:hover::before {
      color: var(--text-secondary-hover, rgba(41, 35, 35, 0.72));
    }
  }

  &.card__earn {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 24px;
    padding-bottom: 18px;

    &.pending {
      min-height: 100%;
    }
  }

  &.card__email {
    position: relative;
    .close {
      position: absolute;
      top: 24px;
      right: 24px;
      img {
        width: 24px;
        height: 24px;
      }
    }

    .lg.accent {
      width: 48px;
      height: 48px;
      & > div {
        height: 16px;
        min-width: 16px;
        width: 16px;
        font-size: 12px;
      }

      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }

      @media (min-width: 1024px) {
        width: 64px;
        height: 64px;
        img {
          width: 32px;
          height: 32px;
        }
        & > div {
          height: 24px;
          min-width: 24px;
          max-width: 64px;
          font-size: 16px;
        }
      }
    }
  }

  .card {
    &__title {
      font-variation-settings: "wght" 700;
      letter-spacing: 0.02em;
      font-size: 24px;
      line-height: 32px;
      color: var(--text-primary);
    }

    &__info {
      letter-spacing: 0.01em;
      color: var(--text-secondary);
    }

    &__position-percents {
      letter-spacing: 0.01em;
      color: var(--text-negative);

      &.up {
        color: var(--text-positive);
      }
    }

    &__waring-badge {
      margin-top: 6px;
      background: var(--fill-negative-bg, rgba(240, 165, 204, 0.16));
      backdrop-filter: blur(12px);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;

      img {
        height: 16px;
      }
    }

    &__earnbar {
      ${earnBarStyles};
      @media (min-width: 1024px) {
        & > div:nth-child(3) {
          display: none;
        }
      }
    }

    &__lendinfo {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &__lendtime {
      letter-spacing: 0.03em;
      color: var(--text-secondary);
      font-variation-settings: "wght" 700;
    }
  }

  .buttons {
    @media (min-width: 1024px) {
      display: flex;
      gap: 8px;
      justify-content: center;
      > * {
        flex: 1 1 50%;

        &:nth-child(2) {
          display: none;
        }
      }
    }
  }

  .pending-div {
    position: absolute;
  }

  &.pending {
    min-height: 330px;
    position: relative;
    pointer-events: none;
    overflow: hidden;
    box-shadow: none;
    z-index: 4;
    transform: translateZ(0);

    &::before {
      display: none;
    }

    .pending-div {
      display: block;
      top: 0;
      left: 0;
      height: 100%;
      width: calc(100% + ${STRIPE_WIDTH});
      background: repeating-linear-gradient(
        -45deg,
        var(--background-secondary) 25%,
        var(--background-secondary) 50%,
        var(--fill-elevated) 50%,
        var(--fill-elevated) 75%
      );
      background-size: ${STRIPE_WIDTH} ${STRIPE_WIDTH};
      animation: ${PENDING_BACKGROUND_ANIMATION} 1.5s linear infinite;
      z-index: 8;
    }
  }
`;

export const LiquidationBlock = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 2px;
  line-height: 1;
  white-space: nowrap;
  color: var(--text-secondary, rgba(66, 61, 60, 0.64));

  svg {
    // filter: invert(var(--svg-invert));
  }

  > span {
    color: var(--text-secondary, rgba(66, 61, 60, 0.64));
  }

  &.margincall {
    color: var(--text-negative, #e54796);
    > span {
      color: var(--text-negative, #e54796);
    }

    svg path {
      fill: var(--text-negative, #e54796) !important;
    }
  }

  img {
    margin-bottom: -1px;
  }

  .hint {
    display: inline-flex;
    justify-content: center;
    transition: width ${ANIMATION_DURATION_MS}, opacity ${ANIMATION_DURATION_MS};
    width: 87.5px;

    > span {
      white-space: nowrap;
      transition: transform ${ANIMATION_DURATION_MS};
    }

    &.animated {
      opacity: 0;
      width: 0;

      > span {
        transform: translate(-25%, 0);
      }
    }
  }
`;

export const TradePercents = styled.div`
  line-height: 16px;
  letter-spacing: 0.5px;
  color: var(--text-positive);
  opacity: 0;
  transition: opacity ${ANIMATION_DURATION_MS};
  &.animated {
    opacity: 1;
  }
  &.bad > div {
    color: var(--text-negative);
  }
  div {
    text-align: right;
    font-size: 12px;
  }
  .${Class.Caption} {
    margin-top: 2px;
  }
`;

export const ConnectButton = styled(Button)`
  display: flex;
  font-weight: 400;
  font-variation-settings: "wght" 700;
`;
