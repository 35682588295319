import T from "@marginly/ui/components/typography";
import NextLink from "next/link";
import styled from "styled-components";

import ArrowRightIconUnstyled from "@/icons/arrow-right-icon";

export const ArrowRightIcon = styled(ArrowRightIconUnstyled)`
  margin: auto;
  path {
    fill: var(--icon-tertiary);
  }
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const Wrapper = styled(NextLink)`
  height: 80px;
  padding: 16px 24px 16px 16px;
  border-radius: 24px;
  background-color: var(--fill-elevated);
  display: flex;
  gap: 16px;
  box-shadow: 0px 8px 24px 0px #33140014;
  box-shadow: 0px 4px 8px 0px #3314000a;
`;

export const Description = styled(T)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RightSide = styled.div`
  overflow: hidden;
`;
