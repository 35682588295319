"use client";
import { createContext } from "react";
import { Chain } from "viem";

import { Partner } from "@/util/server/env";

import Space from "../common/space";
import { GlobalWrapper, Wrapper } from "../common/wrapper.styled";
import { HomepageBottom } from "./homepage-bottom";
import HomepageTop from "./homepage-top";
import { useContestRank } from "./hooks/use-contest-rank";
import { HomeDispatch, HomeState, useHomepage } from "./state";

export const HomePageContext = createContext<
  Partial<{ state: HomeState; dispatch: HomeDispatch }>
>({});

interface Props {
  partner: Partner | undefined;
  chainName: string;
  chain: Chain;
}

const HomePageWrapper = ({ partner, chainName, chain }: Props) => {
  const [state, dispatch] = useHomepage();
  const { position, userInfo } = useContestRank(partner, chainName);

  return (
    <HomePageContext.Provider value={{ state, dispatch }}>
      <GlobalWrapper>
        <Wrapper className="home">
          <HomepageTop
            chain={chainName}
            partner={partner}
            contestRank={position}
            userInfo={userInfo}
          />
          <HomepageBottom
            chain={chain}
            registered={Boolean(userInfo?.refCode)}
          />
        </Wrapper>
      </GlobalWrapper>
      <Space height={72} heightMobile={48} />
    </HomePageContext.Provider>
  );
};

export default HomePageWrapper;
