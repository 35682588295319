"use client";
import Typography from "@marginly/ui/components/typography";
import { useEffect } from "react";
import { useAccount } from "wagmi";

import { getTokenSymbol } from "@/app/util";
import { ZERO } from "@/constants/math";
import { useWeb3Context } from "@/contexts/web3";
import { useMarginlyApi } from "@/hooks/api";
import { usePoolCoeffs, usePoolData } from "@/hooks/position";
import { useApply } from "@/hooks/util";
import { UserPointsResponse } from "@/types/api";
import { formatNum } from "@/util/format";
import { formatCoin } from "@/util/format-coin";

import { checkIsLend } from "../../util/position-helpers";
import { ActiveTokenImage } from "../common/token/active-token-image";
import { SparkScoreLabel } from "../ui/spark-score-label";
import {
  BottomRow,
  Coins,
  LendIconWrapper,
  TokenName,
  TradeCoinRow,
  ValuesSection,
} from "./styled";
import { PoolsSetState } from "./types";

const CoinIcons = ({
  poolData,
  isBaseToken,
}: {
  poolData: {
    base?: { symbol: string; name: string };
    quote?: { symbol: string; name: string };
  };
  isBaseToken: boolean;
}) => (
  <Coins>
    <ActiveTokenImage token={poolData.base?.symbol} isActive={isBaseToken} />
    <ActiveTokenImage token={poolData.quote?.symbol} isActive={!isBaseToken} />
  </Coins>
);

const LendBlock = ({
  address,
  setAllCollateral,
  userPoints,
}: {
  address?: `0x${string}`;
  setAllCollateral?: PoolsSetState;
  userPoints: UserPointsResponse | undefined;
}) => {
  const pointDetails = address && userPoints?.detailsByPool[address];

  const poolData = usePoolData(address);
  const isLend = checkIsLend(poolData.position?.type);
  const poolCoeffs = usePoolCoeffs(address);
  const positionParams = useApply(poolData.getPositionParams, poolCoeffs);

  const account = useAccount();
  const { internalChainId } = useWeb3Context();
  const { data: positionDetails } = useMarginlyApi({
    endpoint: "positionDetails",
    args: [internalChainId, address, account.address],
  });
  const { data: apr } = useMarginlyApi({
    endpoint: "poolApr",
    args: [internalChainId, address],
  });

  useEffect(() => {
    if (address) {
      setAllCollateral?.((previousState) => ({
        ...previousState,
        [address]:
          isLend && positionDetails ? positionDetails.totalCollateral : ZERO,
      }));
    }
  }, [address, isLend, positionDetails, setAllCollateral]);

  const { data: lendPositionEarned } = useMarginlyApi({
    endpoint: "lendPositionEarned",
    args: [internalChainId, address, account.address],
  });

  const bonusSection = (
    <SparkScoreLabel score={pointDetails?.points} short className="score" />
  );

  const lendQuote = lendPositionEarned?.quote;
  const lendBase = lendPositionEarned?.base;

  return (
    isLend && (
      <>
        {positionParams?.realBaseAmount.gt(0) && (
          <TradeCoinRow>
            <CoinIcons poolData={poolData} isBaseToken={true} />
            <ValuesSection>
              <TokenName>
                {formatCoin(positionParams?.realBaseAmount)}{" "}
                {getTokenSymbol(poolData.base?.symbol)}
              </TokenName>
              <BottomRow>
                {Boolean(lendBase) && (
                  <>
                    <Typography caption className={"pnl"}>
                      {(lendBase ?? 0) < 0 ? "" : "+"}
                      {formatCoin(lendBase)}
                    </Typography>
                    <Typography caption secondary>
                      &nbsp;·&nbsp;
                    </Typography>
                  </>
                )}

                <Typography caption secondary className={"apr"}>
                  ·{" "}
                  {formatNum(apr?.lendCurrentBaseApr || 0, {
                    significantDecimals: 2,
                  }) + "%"}
                </Typography>
              </BottomRow>
            </ValuesSection>
            {bonusSection}
          </TradeCoinRow>
        )}
        {positionParams?.realQuoteAmount.gt(0) && (
          <TradeCoinRow>
            <CoinIcons poolData={poolData} isBaseToken={false} />
            <ValuesSection>
              <TokenName>
                {formatCoin(positionParams?.realQuoteAmount)}{" "}
                {getTokenSymbol(poolData.quote?.symbol)}
              </TokenName>
              <BottomRow>
                {Boolean(lendQuote) && (
                  <>
                    <Typography caption className={"pnl"}>
                      {(lendQuote ?? 0) < 0 ? "" : "+"}
                      {formatCoin(lendQuote)}
                    </Typography>
                    <Typography caption secondary>
                      &nbsp;·&nbsp;
                    </Typography>
                  </>
                )}
                <Typography caption secondary className={"apr"}>
                  ·{" "}
                  {formatNum(apr?.lendCurrentQuoteApr || 0, {
                    significantDecimals: 2,
                  }) + "%"}
                </Typography>
              </BottomRow>
            </ValuesSection>
            {bonusSection}
          </TradeCoinRow>
        )}
      </>
    )
  );
};

export default LendBlock;
