import React from "react";

import { UserPointsResponse } from "@/types/api";
import { PoolConfigWithTokens } from "@/types/core";

import { FarmPositionCard } from "../farm-position-card";
import * as S from "./styled";

interface Props {
  pools: PoolConfigWithTokens[] | undefined;
  chainName: string;
  userPoints: UserPointsResponse | undefined;
}

export function FarmPositions({ pools, chainName, userPoints }: Props) {
  const farmPools = pools?.filter((pool) => pool.isFarm);
  return (
    <S.Wrapper>
      {farmPools?.map(({ address }) => {
        return (
          <FarmPositionCard
            key={address}
            poolAddress={address}
            chainName={chainName}
            userPoints={userPoints}
          />
        );
      })}
    </S.Wrapper>
  );
}
